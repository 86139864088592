import logo from './logo.svg';
import './App.css';
import hpImage from './assets/homepage.png'
import video from './assets/video1.mp4'
import airportmain from './assets/airport.jpg'
import sc1 from './assets/iphonesc1.png'
import sc2 from './assets/ihponesc2.png'
import sc3 from './assets/ihponesc3.png'
import sc4 from './assets/pio1.png'
import sc5 from './assets/pio2.png'
import sc6 from './assets/pio3.png'
// import sc7 from './assets/App-Home.PNG'
import sc8 from './assets/App-Home2.PNG'
import sc9 from './assets/App-Home-3.jpeg'
import sc10 from './assets/App-Home-4.jpeg'
import sc11 from './assets/App-Home-5.PNG'
import sc12 from './assets/App-Home-6.jpeg'
import { FaGithubSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { MdDownloadForOffline } from "react-icons/md";
import { MdAlternateEmail } from "react-icons/md";
import me from './assets/me.jpg'




function App() {
  return (
    <div className="main-container">

      <div className='bg-gray'>
        <div>
          <a className='link' href="https://www.linkedin.com/public-profile/settings?trk=d_flagship3_profile_self_view_public_profile" target="_blank">
          <p className='title'>@ deionstfleur</p>

          </a>
        </div>


        <div className='main-home-row'>
        <div className='main-left'>

      <div className='me-logo-row'>
          <p className='main-name'>I'm Deion St.Fleur</p>
          <img src={me} className="me-logo" />
      </div>

          <p className='main-copy'>As a dedicated developer, I thrive on the satisfaction of transforming concepts into polished, high-performing digital products.</p>



    {/* <div className='mx150'>
        <a className='link2' href="mailto:deionstfleur13@gmail.com">
          <div className='msg-btn'>
            <p className='msg-btn-copy'>Leave a message</p>
          </div>
        </a>
    </div> */}


    <div className='top-main-icons-row'>

      <a href="https://github.com/deion-stfleur" target="_blank" className='link2'>
          <div className='tmir-col'>
          <FaGithubSquare className='tmir-icon'/>
          </div>
      </a>

        <a href="https://www.linkedin.com/public-profile/settings?trk=d_flagship3_profile_self_view_public_profile" target="_blank" className='link2'>
          <div className='tmir-col'>
          <FaLinkedin className='tmir-icon'  />
          </div>
        </a>

          {/* <div className='tmir-col'>
          <MdAlternateEmail className='tmir-icon'  />
          </div> */}
    </div>


        </div>

        <div className='main-right'>
          <div>
            <p className='mr-text'>Featured Projects</p>
          </div>


          
          <a href="#Jordans" className='link3'>
          <p className='mr-copy'>Jordans.com <span style={{fontSize: '13px', color: 'yellow', fontWeight: 'bold'}}>(Current Employment)</span></p>
          </a>
          <hr className='mr-line' />

          <a href="#Bonjour" className='link3'>
          <p className='mr-copy'>Bonjour <span style={{fontSize: '13px', color: 'yellow', fontWeight: 'bold'}}>(August 2023)</span></p>
          </a>
          <hr className='mr-line' />

          <a href="#propertio" className='link3'>
          <p className='mr-copy'>Property Data Scraper Project <span style={{fontSize: '13px', color: 'yellow', fontWeight: 'bold'}}>(January 2024)</span></p>
          </a>
          <hr className='mr-line' />

          <a href="#awarenotes" className='link3'>
          <p className='mr-copy'>Awarenotes <span style={{fontSize: '13px', color: 'yellow', fontWeight: 'bold'}}>(Present)</span></p>
          </a>
          <hr className='mr-line' />
        </div>

        </div>



      </div>

      <div id='Jordans' className='section-2'>

        <div className='inner-section'>

          

          <div className='s2-row'>
          <div className='s2-col-left'>
          <p className='s2-job-title'>Jordan's Furniture - Frontend Developer</p>
        
        <div className='inner-pos-col'>
          <p className='role-copy'>In my role as a web and mobile developer, I led the global implementation of a cooking compliance banner, resolving functionality issues and ensuring meticulous cookie data collection. Leveraging Sitecore and React, I created reusable components on the Jordan's Furniture website. I developed and deployed web applications, enhancing site accessibility with third-party software integrations.</p>
          <p className='role-copy'>Using JavaScript and jQuery, I crafted custom filters for shopping pages, resulting in increased engagement. Collaborating closely with UX Designers, I contributed to ideation and optimization using Figma. Additionally, I generated new landing pages to complement marketing and email campaigns, embodying a commitment to robust systems and enhanced user experiences.</p>


          <a target="_blank" href="https://www.jordans.com/" className='linkcta'>
            <p>Link to site</p>
          </a>
        </div>
        
          </div>

        <div className='assets-col'>
          <img className='jp-img' alt="Jordan's Homepage" src={hpImage} style={{}} />

          <video className='vid-css' controls autoPlay>
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
        </div>

          </div>

          <hr className='hr-line' />

          <div id="Bonjour" className='section-3'>

          <div className='s2-row'>
          <div className='s2-col-left'>
          <p className='s2-job-title'>Bonjour - Mobile Development</p>
         
         <div className='inner-pos-col'>
          <p className='role-copy'>Designed as a solution to support immigrants in the Boston area in their quest for affordable and local housing, this mobile platform enables Boston residents and immigrants alike to list available housing and shelter supplies. The app encompasses user features such as registration, housing requests, and options to view nearby leasing opportunities.</p> 
          <p className='role-copy'>Housing providers benefit from features like registration and property allocation, all implemented using React Native, Firebase, and the Google Maps API. Throughout development, I focused on creating dynamic components to enhance the app's functionality and seamlessly integrated new screens/pages to optimize the overall user experience.</p>
         
          <a target="_blank" href="https://apps.apple.com/us/app/bonjour/id6463052996?platform=iphone" className='linkcta'>
            <p>Link to app</p>
          </a>
         
         </div>
          </div>

     

        <div className='assets-col'>
          <img className='jp-img' alt="Jordan's Homepage" src={airportmain} style={{width: '100%', objectFit: 'cover'}} />

          <div className='s3-row'>
            <img src={sc1} />
            <img src={sc2} />
            <img src={sc3} />
          </div>

       
        </div>

          </div>
          </div>
          <hr className='hr-line' />

        </div>

      </div>

      


      <div id="propertio" className='section-3'>

<div className='inner-section'>

  

  <div className='s2-row'>
  <div className='s2-col-left'>
  <p className='s2-job-title'>Property Scraper Project</p>

<div className='inner-pos-col'>
  <p className='role-copy'>The Property Scraper gathers real estate data from online sources, including property listings and details like price, location, and square footage. It covers cities like Boston, MA; San Francisco, CA; and New York City, NY, providing users with insights into the real estate market for informed decision-making. Users can filter properties by price range and access interactive charts to visualize market trends. The project also includes a budget calculation feature to estimate affordability based on income and expenses.</p>
  <p className='role-copy'>The Property Scraper offers property data scraping, filtering by price range, and interactive chart visualization. Users can explore dynamic charts showing property price trends and sales volume. The budget calculation feature helps users estimate affordability and recommends properties based on income and expenses.</p>


  <a target="_blank" href="https://propertio-scraper.netlify.app/" className='linkcta'>
    <p>Link to site</p>
  </a>
</div>

  </div>

<div className='assets-col'>
  <img className='jp-img' alt="Property Scraper Project" src={sc4} style={{}} />
  {/* <img className='jp-img' alt="Property Scraper Project Image 2" src={sc5} style={{}} /> */}
  <img className='jp-img' alt="Property Scraper Project Image 3" src={sc6} style={{}} />

  {/* <video className='vid-css' controls autoPlay>
<source src={video} type="video/mp4" />
Your browser does not support the video tag.
</video> */}
</div>

  </div>

  <hr className='hr-line' />

 
</div>

</div>

<div id="awarenotes" className='section-3'>

<div className='inner-section'>


  <div className='s2-row'>
  <div className='s2-col-left'>
  <p className='s2-job-title'>Awarenotes</p>

<div className='inner-pos-col'>
  <p className='role-copy'>Awarenotes is a cross-platform app that simplifies the process of capturing and organizing handwritten notes, enhancing productivity for users who need efficient document management. Built with React Native, it delivers a seamless mobile experience, while the backend, powered by Firebase and Node.js, ensures fast and reliable performance. Python is integrated to enable OCR (Optical Character Recognition), transforming handwritten notes into searchable, editable text, with Stripe handling the app’s payment system.</p>
  <p className='role-copy'>The app also features AI-powered document insights and real-time collaboration, allowing users to extract key information and collaborate on notes in real-time. PDF generation capabilities make it easy to export documents, and Jest is employed to maintain robust testing throughout the development process. Leading the project, I managed all aspects of the full-stack development, delivering a powerful tool for anyone looking to streamline their note-taking process.</p>


  <a target="_blank" href="https://apps.apple.com/us/app/awarenotes/id6563151353" className='linkcta'>
    <p>Link to app</p>
  </a>
</div>

  </div>

<div style={{display: 'flex', width: '76% !important', margin: 'auto', overflow: 'auto'}} className='assets-col fixx'>
  <img className='jp-img' alt="Awarenotes Home Screen" src={sc8} style={{}} />
  <div style={{height: 2, width: 20, backgroundColor: 'transparent'}}></div>
  <img className='jp-img' alt="Awarenotes App Screen" src={sc9} style={{}} />
  <div style={{height: 2, width: 20, backgroundColor: 'transparent'}}></div>
  <img className='jp-img' alt="Awarenotes App Screen" src={sc10} style={{}} />
  <div style={{height: 2, width: 20, backgroundColor: 'transparent'}}></div>
  <img className='jp-img' alt="Awarenotes App Screen" src={sc11} style={{}} />
  <div style={{height: 2, width: 20, backgroundColor: 'transparent'}}></div>
  <img className='jp-img' alt="Awarenotes App Screen" src={sc12} style={{}} />
</div>
</div>


  <hr className='hr-line' />

 
</div>

</div>
      
      <footer>
        <div className='inner-footer'>

    <div className='ft-outer-row'>
      <div className='ft-btn-row'>
        <a className="link2" href="mailto:deionstfleur13@gmail.com" >
          <div className='ft-btn'>
            <p className='ft-btn-copy'> <MdAlternateEmail />&ensp;Contact Me</p>
          </div>

        </a>
        <div className='sep'></div>
          <a className='link2' target="_blank" href="https://docs.google.com/document/d/e/2PACX-1vS-e4ao7jY9nx4XhfYP8JbEkZ_KsYfzh-JoLscmHJOYQ0g7KAa_yGKRE8QVql3ZgKSJy6vd9WeVsguP/pub">
          <div className='ft-btn'>
            <p className='ft-btn-copy'> <MdDownloadForOffline />&ensp;Resume</p>
          </div>
          </a>
      </div>


<div className='icon-row'>
      <div className='icon-col-left'>

        <a href="https://github.com/deion-stfleur" className='link2 black-icon' target="_blank">
      <FaGithubSquare className='icon' />
      <p className='ic-text'>Github</p>
        </a>
      </div>

      <div>

        <a className='link2 black-icon' href="https://www.linkedin.com/public-profile/settings?trk=d_flagship3_profile_self_view_public_profile" target="_blank">
      <FaLinkedin className='icon' />
      <p className='ic-tex'>Linkedin</p>
        </a>
      </div>

</div>

    </div>
          <p className='td-mark'>@2024 Deion St.Fleur</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
